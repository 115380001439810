export const gradeListType = [
  { label: "304", value: "304" },
  { label: "DD", value: "DD" },
  { label: "JT", value: "JT" },
  { label: "J3", value: "J3" },
  { label: "J1", value: "J1" },
  { label: "316", value: "316" },
  { label: "PPGI", value: "PPGI" },
  { label: "441", value: "441" },
  { label: "SSLNB", value: "SSLNB" },
  { label: "204CU", value: "204CU" },
  { label: "J4(U)", value: "J4(U)" },
  { label: "All", value: "" },
];

export const thicknessCoilListType = [
  { label: "1.75", value: "1.75" },
  { label: "0.76", value: "0.76" },
  { label: "2.8", value: "2.8" },
  { label: "0.88", value: "0.88" },
  { label: "0.55", value: "0.55" },
  { label: "0.68", value: "0.68" },
  { label: "1", value: "1" },
  { label: "1.15", value: "1.15" },
  { label: "4", value: "4" },
  { label: "3.8", value: "3.8" },
  { label: "3.5", value: "3.5" },
  { label: "3", value: "3" },
  { label: "2.5", value: "2.5" },
  { label: "2", value: "2" },
  { label: "1.95", value: "1.95" },
  { label: "1.5", value: "1.5" },
  { label: "1.45", value: "1.45" },
  { label: "1.2", value: "1.2" },
  { label: "1.18", value: "1.18" },
  { label: "0.9", value: "0.9" },
  { label: "0.8", value: "0.8" },
  { label: "0.7", value: "0.7" },
  { label: "0.6", value: "0.6" },
  { label: "0.5", value: "0.5" },
  { label: "0.4", value: "0.4" },
  { label: "0.3", value: "0.3" },
];

export const thicknessPipeListType = [
  { label: "1.75", value: "1.75" },
  { label: "2.8", value: "2.8" },
  { label: "0.76", value: "0.76" },
  { label: "0.88", value: "0.88" },
  { label: "0.55", value: "0.55" },
  { label: "0.68", value: "0.68" },
  { label: "1.45", value: "1.45" },
  { label: "1.15", value: "1.15" },
  { label: "0.65", value: "0.65" },
  { label: "0.9", value: "0.9" },
  { label: "0.7", value: "0.7" },
  { label: "1.95", value: "1.95" },
  { label: "0.8", value: "0.8" },
  { label: "0.6", value: "0.6" },
  { label: "1", value: "1" },
  { label: "0.4", value: "0.4" },
  { label: "2", value: "2" },
  { label: "0.56", value: "0.56" },
];

export const sizeListType = [
  { label: "1/2", value: "1/2" },
  { label: "2.5", value: "2.5" },
  { label: "10*30", value: "10*30" },
  { label: "8*4", value: "8*4" },
  { label: "7/8", value: "7/8" },
  { label: "5/8", value: "5/8" },
  { label: "15*15", value: "15*15" },
  { label: "40*40", value: "40*40" },
  { label: "20*40", value: "20*40" },
  { label: "12*12", value: "12*12" },
  { label: "10*20", value: "10*20" },
  { label: "2", value: "2" },
  { label: "20*20", value: "20*20" },
  { label: "3", value: "3" },
  { label: "35*65", value: "35*65" },
  { label: "40*80", value: "40*80" },
  { label: "10*40", value: "10*40" },
  { label: "80*80", value: "80*80" },
  { label: "25*25", value: "25*25" },
  { label: "10*50", value: "10*50" },
  { label: "50*50", value: "50*50" },
  { label: "50*50(slotted)", value: "50*50(slotted)" },
  { label: "40*40(slotted)", value: "40*40(slotted)" },
  { label: "25*50", value: "25*50" },
  { label: "12*25", value: "12*25" },
  { label: "3/4", value: "3/4" },
  { label: "25*75", value: "25*75" },
  { label: "1.5", value: "1.5" },
  { label: "4", value: "4" },
  { label: "1", value: "1" },
  { label: "30*30", value: "30*30" },
  { label: "3/8", value: "3/8" },
  { label: "1.25", value: "1.25" },
  { label: "10*10", value: "10*10" },
  { label: "100*100", value: "100*100" },
  { label: "All", value: "" },
];

export const statelist = [
  { label: "Andhra Pradesh", value: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
  { label: "Assam", value: "Assam" },
  { label: "Bihar", value: "Bihar" },
  { label: "Chhattisgarh", value: "Chhattisgarh" },
  { label: "Goa", value: "Goa" },
  { label: "Gujarat", value: "Gujarat" },
  { label: "Haryana", value: "Haryana" },
  { label: "New Delhi", value: "New Delhi" },
  { label: "Himachal Pradesh", value: "Himachal Pradesh" },
  { label: "Jharkhand", value: "Jharkhand" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Kerala", value: "Kerala" },
  { label: "Madhya Pradesh", value: "Madhya Pradesh" },
  { label: "Maharashtra", value: "Maharashtra" },
  { label: "Manipur", value: "Manipur" },
  { label: "Meghalaya", value: "Meghalaya" },
  { label: "Mizoram", value: "Mizoram" },
  { label: "Nagaland", value: "Nagaland" },
  { label: "Odisha", value: "Odisha" },
  { label: "Punjab", value: "Punjab" },
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Sikkim", value: "Sikkim" },
  { label: "Tamil Nadu", value: "Tamil Nadu" },
  { label: "Telangana", value: "Telangana" },
  { label: "Tripura", value: "Tripura" },
  { label: "Uttar Pradesh", value: "Uttar Pradesh" },
  { label: "Uttarakhand", value: "Uttarakhand" },
  { label: "West Bengal", value: "West Bengal" },
];

export const helper_name = [
  { label: "", value: "Select Name" },
  { label: "Deepu Kumar", value: "Deepu Kumar" },
  { label: "Tota Ram ", value: "Tota Ram" },
  { label: "Ranjeet", value: "Ranjeet" },
  { label: "Sarvan", value: "Sarvan" },
  { label: "Anil Sharma", value: "Anil Sharma" },
  { label: "Balram", value: "Balram" },
  { label: "Parveen", value: "Parveen" },
  { label: "Narender", value: "Narender" },
  { label: "Digvijay", value: "Digvijay" },
  { label: "Hemant", value: "Hemant" },
  { label: "Sanjeev", value: "Sanjeev" },
  { label: "Pintu", value: "Pintu" },
  { label: "Gholu Kumar", value: "Gholu Kumar" },
  { label: "Stayam", value: "Stayam" },
  { label: "Suraj", value: "Suraj" },
  { label: "Amit Kumar", value: "Amit Kumar" },
  { label: "Jasvir", value: "Jasvir" },
  { label: "Dinesh", value: "Dinesh" },
  { label: "Mukesh", value: "Mukesh" },
];

export const operator_name = [
  { label: "", value: "Select Name" },
  { label: "Jitender", value: "Jitender" },
  { label: "Ranjeet", value: "Ranjeet" },
  { label: "Santosh", value: "Santosh" },
  { label: "Govind", value: "Govind" },
];
