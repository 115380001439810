import React from "react";

const Home = () => {
  // document.addEventListener("contextmenu", (e) => {
  //   e.preventDefault();
  // });
  // var Body = {
  //   // created_on: 2,
  // };
  // api({ api: "/api/company/", body: Body }).then((data) => console.log(data));
  return <div className="Home">Home</div>;
};

export default Home;
