import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  DatePicker,
  Button,
  notification,
  Row,
  Col,
  InputNumber,
} from "antd";
import Select from "react-select";
import { api } from "../../../services/api";
import { RiAddFill } from "react-icons/ri";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

const statusOptions = [
  { value: "coil", label: "Coil" },
  { value: "sheet", label: "Sheet" },
  { value: "pipe", label: "Pipe" },
];

export const Vh_form = ({ visible, onClose, onSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission

  const [form] = Form.useForm();

  const defaultDate = moment(); // Set default date to current date

  useEffect(() => {
    if (visible) {
      const items = form.getFieldValue("items") || [];
      if (items.length === 0) {
        addItem(); // Add a default item if none exists when the modal is opened
      }
    }
  }, [visible]);

  const handleOk = () => {
    setIsSubmitting(true);
    form
      .validateFields()
      .then((values) => {
        const data = values.items.map((item) => ({
          date: item.date
            ? item.date.format("YYYY-MM-DD")
            : defaultDate.format("YYYY-MM-DD"),
          serial_no: item.serial_no,
          vehicle_number: item.vehicle_number,
          party_name: item.party_name,
          quantity: item.quantity,
          no_of_coil: item.no_of_coil,
          status: item.status?.value,
        }));
        api({
          api: "/api/store/",
          method: "post",
          body: { form: data, post: 1 },
        })
          .then(() => {
            Modal.success({
              title: "Success",
              content: "Planning Coil was successfully created.",
              onOk: () => {
                form.resetFields(); // Reset the form fields
                onClose(); // Close the modal
                onSuccess(); // Trigger any success callback
                setIsSubmitting(false); // Reset to false after submission is complete
              },
            });
          })
          .catch(() => {
            Modal.error({
              title: "Failed",
              content: "There was an error creating the Planning Coil.",
              onOk: () => {
                setIsSubmitting(false); // Reset to false if submission fails
              },
            });
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const addItem = () => {
    const items = form.getFieldValue("items") || [];
    form.setFieldsValue({ items: [...items, { date: defaultDate }] });
  };

  const removeItem = (index) => {
    const items = form.getFieldValue("items") || [];
    form.setFieldsValue({ items: items.filter((_, i) => i !== index) });
  };

  return (
    <>
      <Modal
        title="Vehicle Entry"
        open={visible}
        onOk={handleOk}
        onCancel={onClose}
        width={1200}
        footer={[
          <Button key="back" onClick={onClose}>
            Close
          </Button>,
          !isSubmitting && (
            <Button key="submit" type="primary" onClick={handleOk}>
              Submit
            </Button>
          ),
        ]}>
        <Form form={form} layout="vertical" name="vehicle_form">
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Row key={key} gutter={16} className="form-row">
                    <Col xs={24} sm={12} md={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "date"]}
                        fieldKey={[fieldKey, "date"]}
                        label="Date"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the Date!",
                          },
                        ]}>
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder="Date"
                          defaultValue={defaultDate} // Set default value for DatePicker
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={3}>
                      <Form.Item
                        {...restField}
                        name={[name, "serial_no"]}
                        fieldKey={[fieldKey, "serial_no"]}
                        label="Serial No."
                        rules={[
                          {
                            required: true,
                            message: "Please enter the serial number!",
                          },
                        ]}>
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder="Serial Number"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "vehicle_number"]}
                        fieldKey={[fieldKey, "vehicle_number"]}
                        label="Vehicle No."
                        rules={[
                          {
                            required: true,
                            message: "Please enter the vehicle number!",
                          },
                        ]}>
                        <Input placeholder="Vehicle No." />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "party_name"]}
                        fieldKey={[fieldKey, "party_name"]}
                        label="Party Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the party name!",
                          },
                        ]}>
                        <Input placeholder="Party Name" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={2}>
                      <Form.Item
                        {...restField}
                        name={[name, "quantity"]}
                        fieldKey={[fieldKey, "quantity"]}
                        label="Quantity"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the quantity!",
                          },
                        ]}>
                        <InputNumber
                          type="number"
                          step="any"
                          placeholder="Quantity"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={2}>
                      <Form.Item
                        {...restField}
                        name={[name, "no_of_coil"]}
                        fieldKey={[fieldKey, "no_of_coil"]}
                        label="Coils"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the number of coils!",
                          },
                        ]}>
                        <InputNumber type="number" placeholder="No. of Coils" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={3}>
                      <Form.Item
                        {...restField}
                        name={[name, "status"]}
                        fieldKey={[fieldKey, "status"]}
                        label="Status"
                        rules={[
                          {
                            required: true,
                            message: "Please select a status!",
                          },
                        ]}>
                        <Select options={statusOptions} placeholder="Status" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={1}>
                      <Button
                        type="dashed"
                        icon={<RiAddFill size={20} />}
                        onClick={addItem}
                        style={{ marginRight: "20px", marginTop: "25px" }}
                      />
                    </Col>
                    {fields.length > 1 && (
                      <Col xs={24} sm={24} md={1}>
                        <Button
                          type="link"
                          style={{ marginTop: "25px" }}
                          icon={<DeleteOutlined />}
                          onClick={() => removeItem(name)}
                        />
                      </Col>
                    )}
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};
