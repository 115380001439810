import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Col, Row, Button, InputNumber } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Select from "react-select"; // Import react-select
import { api } from "../../../services/api";

const PlanningForm = ({ visible, onClose, onSuccess }) => {
  const [planningType, setPlanningType] = useState("");
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission
  const [CoilLists, setCoilList] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [millOptions, setMillOptions] = useState([]);

  const Brand = [
    { value: "Ramsons Stainless", label: "Ramsons Stainless" },
    { value: "Duromax", label: "Duromax" },
    { value: "Without Stamp", label: "Without Stamp" },
  ];

  const Shift = [
    { value: "Day", label: "Day" },
    { value: "Night", label: "Night" },
  ];

  useEffect(() => {
    api({ api: "/storeitem/coilfilter/" }).then((data) => {
      setCoilList(data);
    });
    api({ api: "/storeitem/sizelist/" }).then((data) => {
      setSizeOptions(data);
    });
    api({ api: "/storeitem/milllist/" }).then((data) => {
      setMillOptions(data);
    });
  }, []);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setPlanningType("");
    }
  }, [visible, form]);

  const handlePlanningTypeChange = (value) => {
    setPlanningType(value);
    form.resetFields(["subForm"]); // Reset subForm fields when type changes

    // Add a default row when a planning type is selected
    form.setFieldsValue({
      subForm: [{}], // Add an empty row in the subForm list
    });
  };

  const handleSelectChange = (value, fieldName, index) => {
    const updatedSubForm = form.getFieldValue("subForm") || [];

    // Fetch the selected coil's data and set the status and statused fields
    if (fieldName === "coilNo" && value) {
      const selectedCoil = CoilLists.find((coil) => coil.value === value.value);
      if (selectedCoil) {
        updatedSubForm[index] = {
          ...updatedSubForm[index],
          coilNo: value,
          weight: selectedCoil.actualweight,
          status: selectedCoil.Status, // Set status here
          statused: selectedCoil.data, // Set statused here
          length: selectedCoil.length,
        };
      }
    } else {
      updatedSubForm[index] = {
        ...updatedSubForm[index],
        [fieldName]: value,
      };
    }

    form.setFieldsValue({ subForm: updatedSubForm }); // Update form with changed field
  };

  const handleSubmit = () => {
    setIsSubmitting(true); // Set to true when submit button is clicked
    form
      .validateFields()
      .then((values) => {
        const planningType = values.planningType.value;
        const subFormValues = values.subForm || [];

        let processedRows = [];

        // Process rows based on the planning type
        if (planningType === "Pipe") {
          processedRows = subFormValues.map((row) => ({
            coilNo: row.coilNo.value,
            brand: row.brand.value,
            millNo: row.millNo.value,
            length: row.length,
            noOfPipe: row.noOfPipe,
            remarks: row.remarks,
            shift: row.shift.value,
            size: row.size.value,
            weight: row.weight,
            status: row.coilNo.Status,
            statused: row.coilNo.data,
          }));
        } else if (planningType === "Polish") {
          processedRows = subFormValues.map((row) => ({
            coilNo: row.coilNo.value,
            noOfPipe: row.noOfPipe,
            weight: row.weight,
            status: row.coilNo.Status,
            statused: row.coilNo.data,
          }));
        }

        api({
          api: "/api/planning/",
          method: "POST",
          body: {
            form: processedRows,
            post: 6,
            modal: planningType,
          },
        })
          .then(() => {
            Modal.success({
              title: "Success",
              content: "Planning Coil was successfully created.",
              onOk: () => {
                form.resetFields();
                onClose();
                onSuccess();
                setIsSubmitting(false);
              },
            });
          })
          .catch(() => {
            Modal.error({
              title: "Failed",
              content: "There was an error creating the Planning Coil.",
              onOk: () => {
                setIsSubmitting(false);
              },
            });
          });
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      title="Planning Form"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Close
        </Button>,
        !isSubmitting && (
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        ),
      ]}
      width={1500}>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={4} xs={24} sm={24} md={3}>
            <Form.Item
              name="planningType"
              label="Planning Type"
              rules={[
                { required: true, message: "Please select a planning type" },
              ]}>
              <Select
                options={[
                  { value: "Pipe", label: "Pipe" },
                  { value: "Polish", label: "Polish" },
                ]}
                onChange={(option) => handlePlanningTypeChange(option.value)}
                placeholder="Select Planning Type"
              />
            </Form.Item>
          </Col>
        </Row>

        {planningType && (
          <Form.List name="subForm">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey }) => (
                  <div key={key} className="sub-form-item">
                    <Row gutter={18} align="middle">
                      <Col span={24} md={4}>
                        <Form.Item
                          name={[name, "coilNo"]}
                          fieldKey={[fieldKey, "coilNo"]}
                          label="Coil No."
                          rules={[
                            { required: true, message: "Coil No. is required" },
                          ]}>
                          <Select
                            options={CoilLists}
                            placeholder="Coil No."
                            onChange={(value) =>
                              handleSelectChange(value, "coilNo", name)
                            }
                          />
                        </Form.Item>
                      </Col>

                      {planningType === "Pipe" && (
                        <>
                          <Col span={24} md={2}>
                            <Form.Item
                              name={[name, "shift"]}
                              fieldKey={[fieldKey, "shift"]}
                              label="Shift"
                              rules={[
                                {
                                  required: true,
                                  message: "Shift is required",
                                },
                              ]}>
                              <Select
                                options={Shift}
                                placeholder="Shift"
                                onChange={(value) =>
                                  handleSelectChange(value, "shift", name)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24} md={3}>
                            <Form.Item
                              name={[name, "millNo"]}
                              fieldKey={[fieldKey, "millNo"]}
                              label="Mill No."
                              rules={[
                                {
                                  required: true,
                                  message: "Mill No. is required",
                                },
                              ]}>
                              <Select
                                options={millOptions}
                                placeholder="Mill No."
                                onChange={(value) =>
                                  handleSelectChange(value, "millNo", name)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24} md={2}>
                            <Form.Item
                              name={[name, "size"]}
                              fieldKey={[fieldKey, "size"]}
                              label="Size"
                              rules={[
                                { required: true, message: "Size is required" },
                              ]}>
                              <Select
                                options={sizeOptions}
                                placeholder="Size"
                                onChange={(value) =>
                                  handleSelectChange(value, "size", name)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24} md={3}>
                            <Form.Item
                              name={[name, "brand"]}
                              fieldKey={[fieldKey, "brand"]}
                              label="Brand"
                              rules={[
                                {
                                  required: true,
                                  message: "Brand is required",
                                },
                              ]}>
                              <Select
                                options={Brand}
                                placeholder="Brand"
                                onChange={(value) =>
                                  handleSelectChange(value, "brand", name)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24} md={2}>
                            <Form.Item
                              name={[name, "length"]}
                              fieldKey={[fieldKey, "length"]}
                              label="Length"
                              rules={[
                                {
                                  required: true,
                                  message: "Length is required",
                                },
                              ]}>
                              <InputNumber
                                min={0}
                                placeholder="Length"
                                style={{ width: "100%" }}
                                onChange={(value) =>
                                  handleSelectChange(value, "length", name)
                                }
                              />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                      <Col span={24} md={2}>
                        <Form.Item
                          name={[name, "noOfPipe"]}
                          fieldKey={[fieldKey, "noOfPipe"]}
                          label="Pipes"
                          rules={[
                            {
                              required: true,
                              message: "No. of Pipes is required",
                            },
                          ]}>
                          <InputNumber
                            min={0}
                            placeholder="No. of Pipes"
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              handleSelectChange(value, "noOfPipe", name)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={2}>
                        <Form.Item
                          name={[name, "weight"]}
                          fieldKey={[fieldKey, "weight"]}
                          label="Weight"
                          rules={[
                            { required: true, message: "Weight is required" },
                          ]}>
                          <InputNumber
                            min={0}
                            placeholder="Weight"
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              handleSelectChange(value, "weight", name)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={2}>
                        <Form.Item
                          name={[name, "remarks"]}
                          fieldKey={[fieldKey, "remarks"]}
                          label="Remarks"
                          rules={[
                            { required: true, message: "Remarks is required" },
                          ]}>
                          <Input
                            placeholder="Remarks"
                            onChange={(e) =>
                              handleSelectChange(
                                e.target.value,
                                "remarks",
                                name
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={2}>
                        <Button
                          type="dashed"
                          danger
                          icon={<PlusOutlined />}
                          onClick={() => add()}
                        />
                        <Button
                          type="primary"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => remove(name)}
                        />
                      </Col>
                      <Form.Item></Form.Item>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        )}
      </Form>
    </Modal>
  );
};

export default PlanningForm;
