import React from "react";
import { api } from "../../../services/api";
import { toast } from "react-toastify";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Row,
  Col,
  Modal,
  message,
} from "antd";
import { UploadOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { statelist } from "../../../services/constant";

const { Option } = Select;

const VendorFormModal = ({ visible, onClose, onSuccess }) => {
  const [form] = Form.useForm();
  const [pictures, setPictures] = React.useState([]);

  const handleImageChange = ({ file }) => {
    if (!file || !file.originFileObj) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setPictures((prevPictures) => [...prevPictures, base64String]);
    };
    reader.readAsDataURL(file.originFileObj);
  };

  const handleSubmit = async (values) => {
    if (values.formList.some((item) => !item.image)) {
      toast.error("Image upload is required for all entries");
      return;
    }

    const updatedFormList = values.formList.map((item, index) => ({
      ...item,
      image: pictures[index] || item.image,
    }));

    api({
      api: "/api/vendorlist/",
      method: "post",
      body: { form: updatedFormList, post: 1 },
    }).then(() => {
      message.success("Form submitted successfully!");
      onSuccess(); // Trigger refresh
      onClose(); // Close the modal
      setPictures([]);
      form.resetFields();
    });
  };

  return (
    <Modal
      title="Company Create"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={1200}>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        initialValues={{ formList: [{}] }}>
        <Form.List name="formList">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <div key={key} style={{ marginBottom: 20 }}>
                  <Row gutter={16}>
                    <Col span={4}>
                      <Form.Item
                        label="GST No."
                        name={[name, "gst_no"]}
                        fieldKey={[fieldKey, "gst_no"]}
                        rules={[
                          { required: true, message: "GST No. is required" },
                        ]}>
                        <Input placeholder="GST No." />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Person Name"
                        name={[name, "contact_name"]}
                        fieldKey={[fieldKey, "contact_name"]}
                        rules={[
                          {
                            required: true,
                            message: "Person Name is required",
                          },
                        ]}>
                        <Input placeholder="Person Name" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Company Name"
                        name={[name, "company_name"]}
                        fieldKey={[fieldKey, "company_name"]}
                        rules={[
                          {
                            required: true,
                            message: "Company Name is required",
                          },
                        ]}>
                        <Input placeholder="Company Name" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Email ID"
                        name={[name, "email_id"]}
                        fieldKey={[fieldKey, "email_id"]}
                        rules={[
                          { required: true, message: "Email ID is required" },
                        ]}>
                        <Input type="email" placeholder="Email ID" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Mobile No"
                        name={[name, "mobile_no"]}
                        fieldKey={[fieldKey, "mobile_no"]}
                        rules={[
                          { required: true, message: "Mobile No is required" },
                        ]}>
                        <Input
                          type="number"
                          placeholder="Mobile No"
                          maxLength={10}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="City"
                        name={[name, "shipping_city"]}
                        fieldKey={[fieldKey, "shipping_city"]}
                        rules={[
                          { required: true, message: "City is required" },
                        ]}>
                        <Input placeholder="City" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item
                        label="Address"
                        name={[name, "shipping_add"]}
                        fieldKey={[fieldKey, "shipping_add"]}
                        rules={[
                          { required: true, message: "Address is required" },
                        ]}>
                        <Input placeholder="Address" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="State"
                        name={[name, "shipping_state"]}
                        fieldKey={[fieldKey, "shipping_state"]}
                        rules={[
                          { required: true, message: "State is required" },
                        ]}>
                        <Select placeholder="Select State">
                          {statelist.map((state) => (
                            <Option key={state.value} value={state.value}>
                              {state.label}
                            </Option>
                          ))}
                          /
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Pin Code"
                        name={[name, "pin_code"]}
                        fieldKey={[fieldKey, "pin_code"]}
                        rules={[
                          { required: true, message: "Pin Code is required" },
                        ]}>
                        <Input type="number" placeholder="Pin Code" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Country"
                        name={[name, "country"]}
                        fieldKey={[fieldKey, "country"]}
                        rules={[
                          { required: true, message: "Country is required" },
                        ]}>
                        <Input placeholder="Country" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Image"
                        name={[name, "image"]}
                        fieldKey={[fieldKey, "image"]}
                        rules={[
                          { required: true, message: "Image is required" },
                        ]}>
                        <Upload
                          accept=".pdf, .jpg, .jpeg, .png"
                          listType="picture"
                          showUploadList={false}
                          fileList={[]}
                          customRequest={({ file, onSuccess }) => {
                            onSuccess(null, file);
                          }}
                          onChange={handleImageChange}
                          maxCount={1}>
                          <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                        {/* Render the uploaded image */}
                        {pictures[name] && (
                          <img
                            src={pictures[name]}
                            alt={`Uploaded ${name}`}
                            style={{ width: "50px", height: "50px" }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Button
                      type="dashed"
                      onClick={() => remove(name)}
                      icon={<MinusOutlined />}
                      style={{ marginRight: 10, marginTop: 30 }}></Button>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        style={{ marginTop: 30 }}></Button>
                    </Form.Item>
                  </Row>
                </div>
              ))}
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default VendorFormModal;
