import React, { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { toast } from "react-toastify";
import { Formate_Date_Time } from "../../../components/Common/datetime";
import { Button, Modal, Form, Input, Row, Col } from "antd";
import Select from "react-select";
import { RiAddFill } from "react-icons/ri";
import { DeleteOutlined } from "@ant-design/icons";
import CreatableSelect from "react-select/creatable"; // Import CreatableSelect from react-select

export const Create_plan_form = ({ visible, onClose, onSuccess }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission

  const chargesSelect = [
    { value: "Reslit", label: "Reslit" },
    { value: "Packing Charges", label: "Packing Charges" },
    { value: "Baby Coil", label: "Baby Coil" },
    { value: "less than 3500kg", label: "less than 3500kg" },
    { value: "More than 15 Coils", label: "More than 15 Coils" },
  ];

  const [commentOptions, setCommentOptions] = useState([
    { value: "Job Work", label: "Job Work" },
    { value: "Production", label: "Production" },
    { value: "Other", label: "Other" },
  ]);

  const initialFormFields = () => ({
    charges: "",
    widths: "",
    comment: "",
    status: "Part-1",
    remarks: "",
    shift: "",
    brand: "",
    actualweight: 0,
    weight: 0,
    coil_no: "",
    step: "Step-2",
    coil_id: 0,
    actual_time: Formate_Date_Time,
    status_id: "",
  });

  const [vendorlistNames, setVendorlist] = useState([]);
  const [form_data, setForms] = useState([{ ...initialFormFields() }]);

  useEffect(() => {
    api({ api: "/storeitem/coilfilter/" }).then((data) => {
      setVendorlist(data);
    });
  }, []);

  const handleAddForm = () => {
    setForms([
      ...form_data,
      { ...initialFormFields(), status: `Part-${form_data.length + 1}` },
    ]);
  };

  const handleCreateComment = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setCommentOptions([...commentOptions, newOption]);
  };

  const handleRemoveForm = (index) => {
    const newForms = [...form_data];
    newForms.splice(index, 1);
    setForms(newForms);
  };

  const handleOk = () => {
    setIsSubmitting(true); // Set to true when submit button is clicked
    api({
      api: "/api/planning/",
      method: "post",
      body: {
        form: form_data,
        status: "Coil",
        post: 2,
      },
    })
      .then(() => {
        Modal.success({
          title: "Success",
          content: "Planning Coil was successfully created.",
          onOk: () => {
            form.resetFields(); // Reset the form fields
            setForms([{ ...initialFormFields() }]); // Reset the form data state
            onClose(); // Close the modal
            onSuccess(); // Trigger any success callback
            setIsSubmitting(false); // Reset to false after submission is complete
          },
        });
      })
      .catch(() => {
        Modal.error({
          title: "Failed",
          content: "There was an error creating the Planning Coil.",
          onOk: () => {
            setIsSubmitting(false); // Reset to false if submission fails
          },
        });
      });
  };

  return (
    <div className="Purchase_form">
      <Modal
        title="Planning Coil"
        open={visible}
        onCancel={onClose}
        width={1200}
        footer={[
          <Button key="back" onClick={onClose}>
            Close
          </Button>,
          !isSubmitting && (
            <Button key="submit" type="primary" onClick={handleOk}>
              Submit
            </Button>
          ),
        ]}>
        {form_data.map((data, index) => (
          <Form form={form} layout="vertical" key={index}>
            <Row gutter={[16, 16]} justify="start">
              <Col xs={24} sm={24} md={6}>
                <Form.Item label="Coil No." required>
                  <Select
                    onChange={(e) => {
                      form_data[index].coil_no = e.label;
                      form_data[index].coil_id = e.value;
                      form_data[index].status_id = e.data;
                      setForms([...form_data]);
                    }}
                    options={vendorlistNames.filter(
                      (names) => names.Status === "Coil"
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={4}>
                <Form.Item label="Charges" required>
                  <Select
                    isMulti
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      form_data[index].charges = selectedValues.join(",");
                      setForms([...form_data]);
                    }}
                    options={chargesSelect}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={4}>
                <Form.Item label="Widths" required>
                  <Input
                    placeholder="157,157,157"
                    value={form_data[index].widths}
                    onChange={(e) => {
                      form_data[index].widths = e.target.value;
                      setForms([...form_data]);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={3}>
                <Form.Item label="Comment(Slitting)" required>
                  <CreatableSelect
                    isClearable
                    onChange={(newValue) => {
                      form_data[index].comment = newValue ? newValue.value : "";
                      setForms([...form_data]);
                    }}
                    onCreateOption={handleCreateComment}
                    options={commentOptions}
                    value={commentOptions.find(
                      (option) => option.value === form_data[index].comment
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={2}>
                <Form.Item label="Status" required>
                  <Input
                    placeholder="Status"
                    value={data.status}
                    onChange={(e) => {
                      form_data[index].status = e.target.value;
                      setForms([...form_data]);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={4}>
                <Form.Item label="Remarks" required>
                  <Input
                    placeholder="Remarks"
                    value={form_data[index].remarks}
                    onChange={(e) => {
                      form_data[index].remarks = e.target.value;
                      setForms([...form_data]);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={1}>
                <Button
                  type="dashed"
                  icon={<RiAddFill size={20} />}
                  onClick={handleAddForm}
                  style={{ marginRight: "20px", marginTop: "25px" }}
                />
              </Col>
              {form_data.length > 1 && (
                <Col xs={24} sm={24} md={1}>
                  <Button
                    type="link"
                    style={{ marginTop: "25px" }}
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemoveForm(index)}
                  />
                </Col>
              )}
            </Row>
          </Form>
        ))}
      </Modal>
    </div>
  );
};
